import React, {useEffect} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";

const entries = [
    {name: 'Home', subEntries: []},
    {name: 'About', subEntries: ['Our Team', 'Our History']},
    {name: 'Services', subEntries: ['Service 1', 'Service 2', 'Service 3']},
    {name: 'Contact', subEntries: []},
];

function SideMenu({handleClick, subEntryClick, selected, entryVal}) {
    const menuQuery = useStaticQuery(
        graphql`
          query  {
          allContentfulKnowledgeHubArticle {
            group(field: entry) {
              fieldValue
              nodes {
                slug
                title
                order
              }
            }
          }
        }
        `
    )
    const categories = menuQuery.allContentfulKnowledgeHubArticle.group;
    categories.sort(function(a, b) {
        const nameA = a.nodes[0].order; // ignore upper and lowercase
        const nameB = b.nodes[0].order; // ignore upper and lowercase
        if (nameA > nameB) {
            return 1;
        }
        if (nameA < nameB) {
            return -1;
        }
        return 0;
    });

    return (
        <>
            {categories.map((entry, index) => (
                <div
                    key={entry.fieldValue}
                    className={`${
                        entry.fieldValue === entryVal ? 'border-2 border-accent bg-gray-100 text-gray-900 font-grotesk-medium' : 'text-gray-600'
                    } group rounded-2xl pb-1`}

                >
                    <Link to={`/help/${entry.nodes[0]["slug"]}/`}
                        // className="text-gray-800 hover:text-gray-500"
                          style={{textDecoration: 'none', color: 'inherit'}}>

                        <div
                            className="pl-4 py-2 flex items-center text-base font-grotesk-medium text-navy"
                            onClick={() => handleClick(index, entry.fieldValue)}
                        >

                            {entry.fieldValue}
                        </div>
                    </Link>
                    {entry.fieldValue === entryVal &&
                        entry.nodes.map((subEntry) => (
                            <div
                                key={subEntry.slug}
                                className="pl-9 pr-6 py-2 flex items-center font-grotesk-normal text-navy text-base group-hover:text-gray-900"
                                onClick={() => subEntryClick(subEntry.title)}
                            >
                                <Link to={`/help/${subEntry.slug}/`}
                                    // className="text-gray-800 hover:text-gray-500"
                                      style={{textDecoration: 'none', color: 'inherit'}}>
                                    {subEntry.title}

                                </Link>

                            </div>
                        ))}
                </div>
            ))}
        </>
    );
}

export default SideMenu;