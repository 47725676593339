import React from "react"
import {graphql} from "gatsby"
import styled from "styled-components";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";

const Text = ({children}) => (
    <div><p>{children}</p></div>
)
const OlView = ({children}) => (
    <div className="inline-block">
        {children.map((child, i) => (
            <div className="flex flex-nowrap items-baseline">
                <div className="mr-2">{i+1}.</div>
                <div>{child}</div>
            </div>
        ))}
    </div>
)
const UlView = ({children}) => (
    <div className="inline-block">
        {children.map((child, i) => (
            <div className="flex flex-nowrap items-baseline">
                <div className="mr-2">•</div>
                <div>{child}</div>
            </div>
        ))}
    </div>
)


const renderOptions = {
    renderMark: {
        [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
    },
    renderNode: {
        // [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [INLINES.HYPERLINK]: (node, children) => {
            return <span
                className="text-purple hover:underline underline-offset-2 hover:cursor-pointer">{children}</span>;
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return <div className="text-xl text-left font-grotesk-bold mb-5">{children}</div>;
        },
        [BLOCKS.UL_LIST]: (_node, children) => {
            return (
                <UlView>
                    {children.map((child, i) => {
                        return child;
                    })}
                </UlView>
            );
        },
        [BLOCKS.OL_LIST]: (_node, children) => {
            return (
                <OlView>
                    {children.map((child, i) => {
                        return child;
                    })}
                </OlView>
            );
        },
        [BLOCKS.LIST_ITEM]: (_node, child) => {
            return <div>{child}</div>;
        },
    },
}

function RenderRichText({rawBody}) {
    // console.log(rawBody)

    return (
        <div>
            <div className="text-slate-800 py-10 max-w-prose">{documentToReactComponents(rawBody, renderOptions)}</div>

        </div>
    );
}

export default RenderRichText;

