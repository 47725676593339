import React, {useEffect, useState} from 'react';
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby"
import {Container} from "../styles/global";
import RenderRichText from "./renderRichText";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {Divide} from "react-feather";
import SideMenu from "./KnowledgeHub/SideMenu";

export default function ArticlePageTemplate({location, data}) {
    const article = data.contentfulKnowledgeHubArticle;
    const metadata = {
        title: "Jinolo Knowledge Base",
        description: "Get help with Jinolo to get started, upload and share files."
    }
    const [selected, setSelected] = useState(null);
    const [title, setTitle] = useState('');
    const [currentSubEntry, setCurrentSubEntry] = useState('');
    const [currentEntry, setCurrentEntry] = useState('');

    const handleClick = (index, name) => {
        setCurrentSubEntry('');
        setCurrentEntry(name);
        setSelected(index);
        setTitle(name);
    };

    const subEntryClick = (name) => {
        setCurrentSubEntry(name)
    }

    useEffect(() => {
        // console.log(article)
        if (currentSubEntry && title !== currentSubEntry) {
            setTitle(currentSubEntry)
        } else if (!currentSubEntry && title !== currentEntry) {
            setTitle(currentEntry)
        }

    }, [currentEntry, currentSubEntry]);

    return (
        <Layout location={location}>
            <SEO
                title={article.entry}
                description={article.description.description}

            />
            <Container>
                <BlogGroup>
                    <div className="font-grotesk-bold text-navy text-center text-5xl mb-6">{metadata.title}</div>
                    <p className="mb-10 mt-3 font-grotesk-medium text-center">{metadata.description}</p>
                    <div className="h-7"/>
                    <div className="flex flex-wrap md:flex-row mt-10">
                        <div className="w-full md:w-1/4 px-2 pb-10 md:pb-1 cursor-pointer">
                            <SideMenu
                                handleClick={handleClick}
                                subEntryClick={subEntryClick}
                                selected={selected}
                                entryVal={article.entry}
                            />
                        </div>
                        <div className="w-full md:w-3/4 pl-6 pr-2 text-navy">
                            {/*<div className="text-sm text-gray-500 text-left">*/}
                            {/*    {currentSubEntry ? `${currentEntry} / ${currentSubEntry}` : `${currentEntry}`}*/}
                            {/*</div>*/}
                            <div className="text-2xl text-left font-grotesk-bold">
                                {article.title}
                            </div>
                            {/*<div className="text-slate-800 py-10 max-w-prose">{documentToReactComponents(JSON.parse(article.richBody.raw))}</div>*/}
                            <RenderRichText rawBody={JSON.parse(article.richBody.raw)}/>
                        </div>
                    </div>
                </BlogGroup>
            </Container>

        </Layout>
    )
}

const BlogGroup = styled.div`

  padding: 160px 20px 10px 20px;
  position: relative;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  // margin: 0;
  // padding: 20px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.navy};
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.navy};
  }

  p {
    color: ${props => props.theme.color.navy};
    margin-bottom: 24px;
  }
`
const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #121640;
  margin-bottom: 44px;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 32px;
  }
`

export const pageQuery = graphql`
  query KnowledgeHubArticleBySlug(
    $slug: String!
  ) {
    contentfulKnowledgeHubArticle(slug: { eq: $slug }) {
    slug
    title
    entry
    publishDate(formatString: "MMMM Do, YYYY")
    rawDate: publishDate
    richBody {
        raw
    }
    description {
      description
    }
  }
  }
`
